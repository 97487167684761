<template>
  <div id="app" class="p-4 text-white bg-black">
    <div
      v-if="isLoading"
      class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center flex-col bg-black z-50"
    >
      <i
        class="fas fa-circle-notch fa-spin text-white text-3xl"
        style="--fa-animation-duration: 0.75s"
      ></i>
      <p class="mt-4 text-white text-lg">Alpha Wallet is loading...</p>
    </div>
    <div
      class="fixed top-0 left-0 right-0 bg-[#212121] text-white py-2 px-2 rounded-lg shadow-md z-10"
    >
      <div class="relative h-4 mb-2 bg-[#3a3a3a] rounded">
        <div
          class="absolute top-0 left-0 h-4 bg-[#5e5e5e] rounded"
          :style="{ width: syncProgress + '%' }"
        ></div>
      </div>
      <div class="flex items-center justify-between px-2">
        <div class="flex items-center">
          <i
            :class="syncIcon"
            class="mr-2"
            style="--fa-animation-duration: 0.5s"
          ></i>
          <span>{{ syncStatus }}</span>
        </div>
        <div class="flex items-center">
          <i class="fas fa-circle text-green-500 mx-2 animate-pulse"></i>
          <span>Connected</span>
        </div>
      </div>
    </div>
    <h1 class="text-2xl font-bold mt-16">3k Wallet 3</h1>
    <h2 class="text-3xl my-2 font-bold">
      <span class="font-">{{ totalBalance }}</span>
    </h2>
    <div class="flex justify-around my-6">
      <button class="flex flex-col items-center justify-center text-xs">
        <span
          class="bg-gray-700 text-black rounded-full text-xl p-2 flex items-center justify-center"
          style="width: 45px; height: 45px"
        >
          <i class="fas fa-plus"></i>
        </span>
        <span class="mt-1">Buy</span>
      </button>
      <button class="flex flex-col items-center justify-center text-xs">
        <span
          class="bg-gray-700 text-black rounded-full text-xl p-2 flex items-center justify-center"
          style="width: 45px; height: 45px"
        >
          <i class="fas fa-minus"></i>
        </span>
        <span class="mt-1">Sell</span>
      </button>
      <button class="flex flex-col items-center justify-center text-xs">
        <span
          class="bg-gray-700 text-black rounded-full text-xl p-2 flex items-center justify-center"
          style="width: 45px; height: 45px"
        >
          <i class="fas fa-exchange-alt"></i>
        </span>
        <span class="mt-1">Swap</span>
      </button>
      <button class="flex flex-col items-center justify-center text-xs">
        <span
          class="bg-gray-700 text-black rounded-full text-xl p-2 flex items-center justify-center"
          style="width: 45px; height: 45px"
        >
          <i class="fas fa-arrow-up"></i>
        </span>
        <span class="mt-1">Send</span>
      </button>
      <button
        class="flex flex-col items-center justify-center text-xs"
        @click="showQRModal = true"
      >
        <span
          class="bg-gray-700 text-black rounded-full text-xl p-2 flex items-center justify-center"
          style="width: 45px; height: 45px"
        >
          <i class="fas fa-arrow-down"></i>
        </span>
        <span class="mt-1">Receive</span>
      </button>
    </div>
    <div
      v-for="(value, key) in sortedCurrencies"
      :key="key"
      class="flex items-center justify-between my-2 shadow-md rounded-md p-2 bg-black"
    >
      <div class="flex items-center">
        <img
          :src="`/coins/${key.toLowerCase()}.png`"
          alt=""
          class="w-8 h-8 mr-4"
        />
        <div>
          <p class="text-lg font-bold">{{ getCoinName(key) }}</p>
          <p class="text-sm">
            <span :class="['font-mono font-bold', getChangeColor(key)]">
              <i
                :class="{
                  'fas fa-arrow-trend-up': getChangeValue(key) > 0,
                  'fas fa-arrow-trend-down': getChangeValue(key) < 0,
                }"
              ></i>
              {{ getChange(key) }}
            </span>
          </p>
        </div>
      </div>
      <div class="text-right">
        <p class="text-md font-bold" v-if="!isLoading">
          ${{
            calculateValue(key).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}
        </p>
        <p class="text-sm font-mono text-gray-500">
          {{ formatBalance(value) }} {{ key }}
        </p>
      </div>
    </div>
    <button
      class="w-full py-2 mt-4 bg-white text-black rounded-xl shadow hover:bg-blue-600"
    >
      <i class="fas fa-gear mr-1"></i>
      Configure Wallet
    </button>
    <p>
      <button
        class="w-full py-2 mt-4 bg-white text-black rounded-xl shadow hover:bg-blue-600"
        @click="forceRefresh()"
      >
        <i class="fas fa-sync mr-1"></i>
        Refresh Wallet
      </button>
    </p>
  </div>
  <div
    v-if="showQRModal"
    class="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
  >
    <div class="bg-white rounded-lg p-6 max-w-md mx-auto relative" @click.stop>
      <button
        class="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
        @click="showQRModal = false"
      >
        <i class="fas fa-times"></i>
      </button>
      <h3 class="text-lg font-bold mb-4">Receive Funds</h3>
      <div class="flex justify-center mb-4">
        <img :src="getQRCodeURL()" alt="QR Code" class="w-40 h-40" />
      </div>
      <p class="text-gray-600 font-mono break-all mb-4">
        {{ receivingAddress }}
      </p>
      <button
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        @click="copyToClipboard(receivingAddress)"
      >
        Copy Address
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      currencies: {
        BTC: 0.00100,
        ETH: 0.004936,
        USDT: 150.3900,
        USDC: 5694.43943,
        bUSDC: 102.30,
        XMR: 14.631476,
        LTC: 17.7973,
        SOL: 0.0001,
        TRX: 1,
        XRP: 0,
        DOT: 0,
      },
      showQRModal: false,
      receivingAddress: "0xCDe3a3DEcE1f80cE6371D4ed2a2D92017A2a624A",
      symbolToName: {
        BTC: "bitcoin",
        ETH: "ethereum",
        USDT: "tether",
        USDC: "usd-coin",
        bUSDC: "usd-coin",
        XMR: "monero",
        LTC: "litecoin",
        SOL: "solana",
        TRX: "tron",
        XRP: "ripple",
        DOT: "polkadot",
      },
      prices: {},
      syncProgress: 0,
      syncStatus: "Starting remote node...",
      syncIcon: "fas fa-sync fa-spin text-orange-500",
      blocksBehind: 1000,
      isLoading: true,
    };
  },
  methods: {
    forceRefresh() {
      this.cacheBustTimestamp = Date.now();
      location.reload();
    },
    async fetchPrices() {
      this.isLoading = true;
      try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Cmonero%2Ctether%2Cusd-coin%2Clitecoin%2Csolana%2Cbnb%2Ctron%2Cripple%2Cpolkadot&vs_currencies=usd&include_24hr_change=true&precision=18",
        {
        headers: {
          accept: 'application/json',
          'x-cg-demo-api-key': 'CG-26gnHbCEqRHTnpsifkkywhuT'
        }
        }
      );
      this.prices = response.data;
      } catch (error) {
      console.error("Error fetching prices:", error);
      } finally {
      this.isLoading = false;
      }
    },
    getCoinName(symbol) {
      const names = {
        BTC: "Bitcoin",
        ETH: "Ethereum",
        USDT: "Tether",
        USDC: "USDC",
        bUSDC: "USDC (Base)",
        XMR: "Monero",
        LTC: "Litecoin",
        SOL: "Solana",
        TRX: "Tron",
        XRP: "Ripple",
        DOT: "Polkadot",
      };
      return names[symbol] || symbol;
    },
    formatBalance(value) {
      return value.toFixed(5);
    },
    calculateValue(symbol) {
      const name = this.symbolToName[symbol];
      const price = this.prices[name]?.usd;
      //console.log(`Price of ${symbol} is ${price} and the balance is ${this.currencies[symbol]}`);
      let final = price ? this.currencies[symbol] * price : 0;
      return final;
    },
    getChange(symbol) {
      const name = this.symbolToName[symbol];
      const change = this.prices[name]?.usd_24h_change;
      return change ? `${change.toFixed(2)}%` : "No data";
    },
    getChangeColor(symbol) {
      const name = this.symbolToName[symbol];
      const change = this.prices[name]?.usd_24h_change;
      return {
        "text-green-500": change > 0,
        "text-red-500": change < 0,
      };
    },
    getChangeValue(symbol) {
      const name = this.symbolToName[symbol];
      const change = this.prices[name]?.usd_24h_change;
      return change || 0;
    },
    startSync() {
      const syncInterval = setInterval(() => {
        this.blocksBehind -= Math.floor(Math.random() * 2);
        if (this.blocksBehind <= 0) {
          this.blocksBehind = 0;
          clearInterval(syncInterval);
          this.syncStatus = "Synchronized";
          this.syncIcon = "fas fa-check text-green-500";
        } else {
          this.syncStatus = `Syncing, ${this.blocksBehind} blocks left`;
        }
        this.syncProgress = 100 - (this.blocksBehind / 1000) * 100;
      }, 10);
    },
    getQRCodeURL() {
      const qrData = `${this.receivingAddress}`;
      return `https://chart.apis.google.com/chart?cht=qr&chs=200x200&chl=${encodeURIComponent(
        qrData
      )}`;
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          alert("Address copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy address: ", err);
        });
    },
  },
  computed: {
    totalBalance() {
      let total = Object.entries(this.currencies)
        .map(([key, value]) => this.calculateValue(key))
        .reduce((acc, value) => acc + value, 0);
      return total.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    sortedCurrencies() {
      return Object.entries(this.currencies)
        .map(([key, value]) => ({
          key,
          value,
          usdValue: this.calculateValue(key),
        }))
        .sort((a, b) => b.usdValue - a.usdValue)
        .reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {});
    },
  },
  async mounted() {
    await this.fetchPrices();
    this.startSync();
  },
};
</script>
